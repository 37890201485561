import React from 'react'
import '../css/LearnPhala.css';

export default function LearnPhala() {
  return (
      <div className="phalaStakeLearn">
        <div id="phalaTextContainer"> 
          <p className="phalaTokenPara"> <b>Phala </b>- A highly scalable secure computing cloud. 
            It works using TEE Mining - leveraging secure enclaves 
            found in Intel SGX capable processors to create a privacy perservering 
            computational layer. Paired with Fat Contracts - like a Smart Contract,
            but able to run more like a traditional server - it has the 
            potential to power WEB3 and beyond.
          </p>
        </div>
          <div id="learnPhala">
            <a href="https://phala.network" >
            <div id='learnPhalaSvg'></div>
            </a>
          </div>
      </div> 
  )
}