import React from 'react'
import '../css/Header.css';
// import NavBootstrap from './NavBootstrap';
export default function Header() {
  return (
    <header className="sec"  id="first" >
        <div id="logoContainer">
        <div id="logo" role="img" aria-label="Cyber Jungle Main Logo"></div>
        </div>
            <nav id="headerNav">
                {/* <NavBootstrap /> */}
            </nav> 
    </header>
  )
}



// {/* <div id="navSelect" className="FRC"> 
//                     <div id="star">O</div>                
//                     <a href="CyberJunlge.io" className="homeNewContact"> Home</a>
//                 </div>
//                 {/* <div id="navSelect" className="FRC">      
//                 <div id="star">O</div>         
//                 <div id="homeNewContact"> News</div>
//                 </div> */}

//                 <div id="navSelect" className="FRC">
//                     <div id="star" >O</div>
//                     <a href="CyberJunlge.io" className="homeNewContact"> Contact</a>
//                 </div> */}