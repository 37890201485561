import React from 'react'
import '../css/Goal.css';

export default function Goal() {
  return (
<>
    <div id="goalContainer">
    <div id="goalHead"  role="img" aria-label="Cyber Jungle Problem Heading"></div>
    <div className='mainParaHolders'>
    <p className="goalPara"> To build useful products that use decentralized infrastructure and 
    provide real world value. Starting at the foundation and working our way up!</p>
    </div>
  </div>
</>
  )
}
