import React from 'react'
import '../css/UpperThird.css';

export default function UpperThird() {
  return (
    <>
      <div className="sec" id="second" >
        <div id="slogan" role="img" aria-label="Cyber Jungle Slogan"></div>
      </div>
      <div className="sec"  id="third" >
        <div id="philContainer">
          <div id="philosophyHead" role="img" aria-label="Cyber Jungle Philosophy Heading"></div>
          <div className='mainParaHolders'>
          <p className="philPara"> To support projects that build a decentralized foundation for
          WEB3 and the METAVERSE. We look for projects that provide services akin to a digital utility 
          that can scale and serve the broader industry and beyond.</p>
          </div>
        </div>
      </div>
      <div className="sec" id="fourth">
          <div id="probContainer">
          <div id="problemHead" role="img" aria-label="Cyber Jungle Problem Heading"></div>
          <div className='mainParaHolders'>
          <p className="probPara">Decentralized projects are often not as decentralized as they seem. While some are advertised as such, the reality is that
          they are often hosted by big tech or private centralized farms.</p>
          </div>
        </div>
      </div>
    </>
  )
}
