import React from 'react'
import '../css/ApproachHeading.css';

export default function ApproachHeading() {
  return (
    <div id='approachHeadHolder'>
      <p className="approachHead"> OUR </p>
      <p className="approachHead"> APPROACH </p>
    </div>
  )
}
