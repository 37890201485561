import React from 'react'
import '../css/LowerThird.css';
import Goal from './Goal';
import Footer from '../images/Footer.svg'

export default function LowerThird() {
    return (
    <div>
        <div className="sec" id="eight">
        <div className='circuitContainer'>
        {/* <img id='circuitFooter' src={circuitFooter} ></img> */}
        </div>
            <Goal />
        </div>
        <div className="sec" id="ninth">
            {/* <div id="teamHead" role="img" aria-label="Cyber Jungle Team Heading"></div>
            <div id="teamContainer" >
            <div id="square" role="img" aria-label="Cyber Jungle Team Box"></div>
            <div id="square" role="img" aria-label="Cyber Jungle Team Box"></div>
            <div id="square" role="img" aria-label="Cyber Jungle Team Box"></div>
            <div id="square" role="img" aria-label="Cyber Jungle Team Box"></div>
            </div>  */}
        </div>
            <footer className="sec" id="bottom">
                <div id="gorillaBox">
                <div id="gorilla_0" role="img" aria-label="Cyber Jungle Gorilla Md"></div>
                <div id="gorilla_1" role="img" aria-label="Cyber Jungle Gorilla Sm"></div>
                <div id="gorilla_2" role="img" aria-label="Cyber Jungle Gorilla Lg"></div>
                </div>
                {/* <div id="contactBox">
                <div id="contact" role="img" aria-label="Cyber Jungle Contact"></div>
                </div> */}
                {/* <div id="footer" role="img" aria-label="Cyber Jungle Footer"></div> */}
                
            </footer>
            <div id='footerImg' src={Footer} ></div>

    </div>
  )
}
