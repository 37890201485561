import React from 'react'
import '../css/ProjectHeading.css';

export default function ApproachHeading() {
  return (
    <div id='projectHeadHolder'>
    <p className="projectHead"> PROJECTS </p>
    <p className="projectHead"> WE SUPPORT </p>
    </div>
  )
}
