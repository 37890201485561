import React from 'react';
import Goals from './Goals';
import LearnPhala from './LearnPhala';
import LearnHelium from './LearnHelium';
import ApproachHeading from './ApproachHeading';
import ProjectHeading from './ProjectHeading'
import '../css/MiddleThird.css';


export default function MiddleThird() {
return (<>
      <div className="sec" id="five">
        <div id="leafAndApproachCon">
          <div id="leftLeaf" role="img" aria-label="Cyber Jungle Left Leaf"></div>
          <ApproachHeading />
        </div>
      <Goals />  
      </div>
      <div className="sec" id="six">
        <div id="secSixFirstHolder">
          <ProjectHeading />
          <div id="rightLeafBox">
          <div id="rightLeaf" role="img" aria-label="Cyber Jungle Right Leaf"></div>
          </div>
        </div>
        <div id="secSixSecondHolder">
          <div id="stakeLearnHolder" className='sixSec'>
          < LearnPhala />
          < LearnHelium /> 
          </div>               
        </div>
        </div>
        <div className="sec" id="seven">  </div>
    </>
  )
}
