import React from 'react'
import '../css/Goals.css';

export default function Goals() {
  return (
      <div id="GoalsNav">
        <div className="goalSelect" > 
          <div className="goalIcon">O</div>                
          <p className="goalText"> 
           Host our own worker nodes on projects like Phala and Crust</p>
        </div>
        <div className="goalSelect" >
          <div className="goalIcon">O</div>
          <p className="goalText"> 
           Build solutions to help the community manage their own 
           nodes and help grow the ecosystems</p>
        </div>
        <div className="goalSelect" >
        <div className="goalIcon">O</div>
          <p className="goalText"> 
            Create products that can provide real world value 
            by utilizing blockchain technology</p>
      </div>
     </div> 
  )
}
