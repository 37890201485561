import React from 'react'
import '../css/LearnHelium.css';

export default function LearnHelium() {
  return (
    <div className="heliumStakeLearn">
      <div id="heliumTextContainer"> 
        <p className="heliumTokenPara"> 
        <b>Helium </b>- A project focused on decentralized wireless networks. 
            Helium debuted as an IoT network that utilizes LoRaWAN and a 
            Proof-of-Coverage consensus. More recently they have expanded into a 
            5G network that uses the same PoC concept to promote rapid 
            infrastructure growth.
            </p>
      </div>
        <div id="learnHelium">
          <a href="https://helium.com" >
          <div id='learnHeliumSvg'></div>
          </a>
          </div>
      </div> 
  )
}