import '../css/App.css';
import Header from './Header';
import UpperThird from './UpperThird';
import MiddleThird from './MiddleThird';
import LowerThird from './LowerThird';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Circuit from '../images/Main_Circuit.svg'

function App() {
  return (
    <div className="container-fluid">
      <main className="AppLayerTop">
        <div id="monkeyContainer">
          <div id="backgroundHead" role="img" aria-label="Cyber Jungle BG Image"></div>
           <section id="upperTransformedContainer">
            <Header />            
            <UpperThird />
            </section>
          <section id="middleTransformedContainer">
            <MiddleThird />
          </section>
          <section id="lowerTransformedContainer">
            <LowerThird />
            </section>
          </div>
        </main>
      </div>
  );
}

export default App;
